import React, { useState, useEffect } from 'react';
import './Dropdown.css'; // Assuming you will style this with CSS
import DropdownIcon from '../icons/Dropdownicon'; // Assuming DropdownIcon is in the same directory
import { useNavigate } from 'react-router-dom';

const UserChat = ({ data = {}, onNameSelect, initialSearchTerm }) => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(() => {
    return localStorage.getItem('selectedUser') || 'Seleccionar usuario';
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  
  // UseEffect para actualizar los usuarios cuando los datos cambian
  useEffect(() => {
    if (data.getUsersLinkByUsername && data.getUsersLinkByUsername.length > 0) {
      const updatedUsers = data.getUsersLinkByUsername;
      if (JSON.stringify(updatedUsers) !== JSON.stringify(users)) {
        setUsers(updatedUsers);
        setFilteredUsers(updatedUsers); // Actualizar usuarios filtrados al mismo tiempo
      }
    }
  }, [data, users]);

  // UseEffect para establecer el valor inicial del término de búsqueda y el usuario seleccionado
  useEffect(() => {
    if (initialSearchTerm) {
      setSearchTerm(initialSearchTerm);
      const filteredUsers = users.filter((user) =>
        user.nombres.toLowerCase().includes(initialSearchTerm.toLowerCase())
      );

      if (filteredUsers && filteredUsers.length > 0) {
        let bestMatchUser = filteredUsers[0];
        let highestSimilarity = 0;

        filteredUsers.forEach((user) => {
          const similarity = calculateCosineSimilarity(user.nombres, initialSearchTerm);
          if (similarity > highestSimilarity) {
            bestMatchUser = user;
            highestSimilarity = similarity;
          }
        });

        if (selectedUser !== bestMatchUser.nombres) {
          setSelectedUser(bestMatchUser.nombres);
          localStorage.setItem('selectedUser', bestMatchUser.nombres);
        }

        if (onNameSelect) {
          onNameSelect(bestMatchUser);
        }
      }
    }
  }, [initialSearchTerm, users, onNameSelect, selectedUser]);

  // Ejecutar onNameSelect al montar el componente si hay un usuario seleccionado en localStorage
  useEffect(() => {
    if (selectedUser !== 'Seleccionar usuario' && onNameSelect) {
      const selectedUserData = users.find((user) => user.nombres === selectedUser);
      if (selectedUserData) {
        onNameSelect(selectedUserData);
      }
    }
  }, [selectedUser, users, onNameSelect]);

  // Función para tokenizar y calcular la similitud de coseno
  const tokenize = (text) => {
    return text.toLowerCase().split(/\s+/);
  };

  const calculateCosineSimilarity = (userName, searchTerm) => {
    const userTokens = tokenize(userName);
    const searchTokens = tokenize(searchTerm);

    const allTokens = Array.from(new Set([...userTokens, ...searchTokens]));

    const userVector = allTokens.map((token) => userTokens.filter((t) => t === token).length);
    const searchVector = allTokens.map((token) => searchTokens.filter((t) => t === token).length);

    const dotProduct = userVector.reduce((sum, val, idx) => sum + val * searchVector[idx], 0);
    const magnitudeUser = Math.sqrt(userVector.reduce((sum, val) => sum + val * val, 0));
    const magnitudeSearch = Math.sqrt(searchVector.reduce((sum, val) => sum + val * val, 0));

    if (magnitudeUser === 0 || magnitudeSearch === 0) return 0;

    return dotProduct / (magnitudeUser * magnitudeSearch);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (user) => {
    setSelectedUser(user.nombres);
    localStorage.setItem('selectedUser', user.nombres); // Guardar en localStorage
    navigate(`/new/chat`);
    setIsOpen(false);
    if (onNameSelect) {
      onNameSelect(user); // Pasa el objeto completo con id, nombres y createdAt
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0);
    const newFilteredUsers = users.filter((user) =>
      user.nombres.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredUsers(newFilteredUsers);
  };

  const usersPerPage = 5;
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);
  const paginatedUsers = filteredUsers.slice(currentPage * usersPerPage, (currentPage + 1) * usersPerPage);

  const nextPage = () => {
    if (filteredUsers.length <= usersPerPage) return;
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  
  return (
    <div className="dropdown-container">
      <div className="dropdown-header" onClick={toggleDropdown}>
        {selectedUser}
        <DropdownIcon />
      </div>
      {isOpen && (
        <div className="dropdown-list">
          <input
            type="text"
            placeholder="Buscar destinatario..."
            value={searchTerm}
            onChange={handleSearch}
            className="dropdown-search"
          />
          {paginatedUsers.map((user) => (
            <div
              key={user.id}
              className="dropdown-item"
              onClick={() => handleSelect(user)}
            >
              {user.nombres}
            </div>
          ))}
          <div className="pagination-controls">
            {currentPage > 0 && <button onClick={prevPage}>&lt;</button>}
            {[...Array(totalPages)].map((_, index) => {
              if (totalPages <= 4) {
                return (
                  <button
                    key={index}
                    onClick={() => setCurrentPage(index)}
                    className={currentPage === index ? 'active' : ''}
                    style={{ fontWeight: currentPage === index ? 'bold' : 'normal' }}
                  >
                    {index + 1}
                  </button>
                );
              } else if (currentPage <= 1 && index < 4) {
                return (
                  <button
                    key={index}
                    onClick={() => setCurrentPage(index)}
                    className={currentPage === index ? 'active' : ''}
                    style={{ fontWeight: currentPage === index ? 'bold' : 'normal' }}
                  >
                    {index + 1}
                  </button>
                );
              } else if (currentPage >= totalPages - 2 && index >= totalPages - 4) {
                return (
                  <button
                    key={index}
                    onClick={() => setCurrentPage(index)}
                    className={currentPage === index ? 'active' : ''}
                    style={{ fontWeight: currentPage === index ? 'bold' : 'normal' }}
                  >
                    {index + 1}
                  </button>
                );
              } else if (index >= currentPage - 1 && index <= currentPage + 2) {
                return (
                  <button
                    key={index}
                    onClick={() => setCurrentPage(index)}
                    className={currentPage === index ? 'active' : ''}
                    style={{ fontWeight: currentPage === index ? 'bold' : 'normal' }}
                  >
                    {index + 1}
                  </button>
                );
              } else if (index === 3 || index === totalPages - 2) {
                return <span key={index}>...</span>;
              }
              return null;
            })}
            {currentPage < totalPages - 1 && <button onClick={nextPage}>&gt;</button>}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserChat;





