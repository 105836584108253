import React from 'react';

const DropdownIcon = () => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g>
        <path fill="none" d="M0 0h24v24H0z"/>
        <path fill="#95c9ee" d="M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z"/>
    </g>
</svg>
  );
}

export default DropdownIcon;

////// #313C44