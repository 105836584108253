import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
////import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/client';
import { Auth0Provider } from '@auth0/auth0-react';
import client from './apolloClient';
///import { BrowserRouter as Router } from 'react-router-dom';


///// PRODUCCION


const dominio = process.env.REACT_APP_AUTH0_DOMINIODEV;
const ideusuario = process.env.REACT_APP_AUTH0_IDDEV
const redirect = process.env.REACT_APP_URI_RDDEV


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

<Auth0Provider 
    domain={dominio}
    clientId={ideusuario}
    authorizationParams={{
      redirect_uri: redirect/// || window.location.origin
    }}
  >
    <ApolloProvider client={client}>
    
      <App />
    
    </ApolloProvider>
  </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
///reportWebVitals();
