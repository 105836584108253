import React, { useState, useEffect } from 'react';
import '../../App.css';
import './SurveyComponent.css';
import { 
    FaCaretDown, 
    FaSignInAlt, 
    FaSignOutAlt,
} from 'react-icons/fa'; 
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from '@apollo/react-hooks';
///import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import Terminos from '../terminosDeUso';
import Suscripciones from '../suscripciones';
import '../Modal.css';
import FilteredPosts from '../historialLinks';
///import { generatePdf } from './modulos-propios/generatePDF';
///import { StreamingText } from './modulos-propios/streamingText';
///import useScrollToBottom from '../modulos-propios/useScrolledToBottom'; 
import useClickOutside from '../modulos-propios/useClickOutside'; 
import Nuevo from '../icons/nuevo';
import AbrirSide from '../icons/arrowLeft';
import CerrarSide from '../icons/arrowRigth';
import { FaCheckCircle } from 'react-icons/fa';
///import client from '../apolloClient';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
///import { useNavigate } from "react-router-dom";
import Copy from '../icons/copy';

const Chat = () => {
  const [showSidebar, setShowSidebar] = useState(true); 
  const [showDropdown, setShowDropdown] = useState(false);
  useClickOutside(showDropdown, setShowDropdown); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuscripcionesOpen, setSuscripcionesOpen] = useState(false);
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { loginWithRedirect } = useAuth0();
  const { logout } = useAuth0();
  const navigate = useNavigate();
  

  const useradmin = [
    process.env.REACT_APP_ADMIN,
    process.env.REACT_APP_ADMIN2,
    process.env.REACT_APP_ADMIN3,
    process.env.REACT_APP_ADMIN4
  ];

  const isAdmin = (currentUser) => {
    return useradmin.includes(currentUser);
  };


  
  const username = user?.sub?.replace(/[^a-zA-Z0-9]/g, '');

  const { loadingR, errorR, dataR, refetch } = useQuery(FETCH_POSTS_QUERY_USER_LINK, {
    variables: { username }
  });


  const location = useLocation();

  useEffect(() => {
    if (location.state?.refetchNeeded) {
      refetch();
    }
  }, [location.state, refetch]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowSidebar(false);
      }
    };
    handleResize(); 
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };


  const handleNewChat = () => {
    navigate(`/new/chat`, { state: { destinatario } });
    refetch();
};


  const handleGestionar = () => {
    navigate(`/userlinkOwner`);
    refetch();
  };

  const handleVolver = () => {
    navigate(`/userlinkOwner`);
};

  const [userlink, setUserLink] = useState('');
  const [destinatario, setDestinatario] = useState('');
  const [email, setEmail] = useState('');
  const [estado, setEstado] = useState('');
  const [showCopyIcon, setShowCopyIcon] = useState(true);
  const { postId } = useParams();
  const { loading, error, data } = useQuery(FETCH_POST_LINK, {
      variables: { postId },
      skip: postId ? false : true,
  });

  useEffect(() => {
      if (data && data.getPosLink) {
          const post = data.getPosLink;
          setUserLink(post.linkOwner);
          setDestinatario(post.destinatario);
          setEmail(post.emailOwner);
          setEstado(post.estado);
      }
  }, [data]);





  const currentUrl = window.location.origin + location.pathname.replace('/estadoLink/', '/userlink/');

  const handleCopyLink = () => {
      navigator.clipboard.writeText(currentUrl).then(() => {
          setShowCopyIcon(false);
          setTimeout(() => setShowCopyIcon(true), 2000);
      }).catch((err) => {
          console.error('Error al copiar el enlace: ', err);
      });
  };

 

  return (
    <div className="chat-container">
      <div>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>Cargando...</div>
            ) : (
                estado === 'pendiente' ? (
                    <div className="contexto-link">
                        <div className="survey-container">
                        <h3>Enlace test Conductual-GPT generado</h3>
                        <p class="info" style={{ width: '50%' }}> Copia y comparte el enlace con la persona que realizará el test conductual. El enlace solo podrá usarse una vez. Si deseas enviar el test a <strong>otra persona</strong> después de su uso, deberás generar un <strong>nuevo enlace</strong>.</p>
                            <div className="link-container" style={{ display: 'flex', alignItems: 'center' }}>
                                <p className="link-text" style={{ userSelect: 'none', textDecoration: 'none', pointerEvents: 'none', marginRight: '30px' }}>{currentUrl}</p>
                                <button onClick={handleCopyLink} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', border: 'none', background: 'none' }}>
                                    {showCopyIcon ? <Copy size={15} /> : <FaCheckCircle style={{ color: 'green' }} />}
                                    <p style={{ marginLeft: '5px' }}>{showCopyIcon ? 'Copiar' : 'Copiado'}</p>
                                </button>
                            </div>
                            <p className="warningLink">El enlace NO ha sido usado!</p>
                            
                        </div>
                    </div>
                ) : (
                    <div className="contexto-link">
                        <div className="survey-container">
                            <p className="exitoLink">
                                El enlace ha sido usado!
                                <span className="chat-icon-container" onClick={handleNewChat}>
                                    <Nuevo size={7} />
                                    <p>Chatear</p>
                                </span>
                            </p>
                            
                        </div>
                    </div>
                )
            )}
        </div>
      <div className="floating-menu">
{/* MENU DESPLEGABLE, Y LOGO QUE ESTA EN EL HEADER #PUBLICO# [Login]*/}
        <img src='/images/LOGO-conductualGPT-01.svg' className="floating-menu-logo-2" />
        <div className="sidebar-buttons">
          {!isAuthenticated && (
            <button onClick={() => loginWithRedirect()}>
              <FaSignInAlt />
              <span>Ingresar</span>
            </button>
          )}
        </div>
        {isAuthenticated && (
          <div className="profile-container">
{/* MENU DESPLEGABLE QUE ESTA EN EL HEADER [Salir, Entrenar, Ir al chat, Links]*/}
            <img src={user.picture} alt={user.name} />
            <div id="dropdown" className="dropdown">
              <button onClick={handleDropdownToggle}>
                <FaCaretDown />
              </button>
              {showDropdown && (
                <div className="dropdown-menu">
              
                    <button>
                      <a className='button-link' href="#" onClick={() => navigate(`/new/chat`)}>Ir al Chat</a>
                    </button>
                   
                    <button>
                      <a className='button-link' href="#" onClick={() => navigate(`/userlinkOwner`)}>Gestionar Links</a>
                    </button>
                 
                    {isAdmin(user.sub) && ( <button><a className='button-link' href="#" onClick={() => navigate(`/trained`)}>Entrenar</a></button>)}
                  <button>
                    <a className='button-link' href="#" onClick={() => setSuscripcionesOpen(true, setShowSidebar(false)) }>Suscripcion</a>
                  </button>
                  <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                    <span>Salir</span>
                    <FaSignOutAlt />
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {isAuthenticated && (
        <div className={`sidebar ${showSidebar ? 'open' : 'close'}`}>
{/* BARRAL LATERAL IZQUIERDA HISTORIAL Y DEMAS*/}
          <div className="sidebar-toggle" onClick={toggleSidebar}>
            {showSidebar ? <AbrirSide /> : <CerrarSide />}
          </div>
          <div className="sidebar-content">
            <div className="button-container">
              <button onClick={handleGestionar}>
                <Nuevo size={10}/>
                <p>Gestionar Links</p>
              </button>
            </div>
            <p>Links creados.</p>
            <FilteredPosts refetch={refetch} user={user.sub} />
          </div>
        </div>
      )}
      <div className="main">
{/* ACA ESTARIA EL INPUT CHAT EN LAS OTRAS PAGINAS, TAMBIEN TERMINOS Y CONDICIONES*/}
        <div className="disclaimer-base-container">
          <p>Conductual-GPT ™ 2024 | by Nerualdinamica LLC</p>
        </div>
      </div>
     
      <div className="disclaimer-base-container-terminos">
        <a className='button-link' href="#" onClick={() => setIsModalOpen(true)}>Terminos de uso.</a>
        <Terminos isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        <Suscripciones isOpen={isSuscripcionesOpen} onClose={() => setSuscripcionesOpen(false)} />
      </div>
    </div>
  );
};




const FETCH_POSTS_QUERY_USER_LINK = gql`
  query getPostsLinkByUsername($username: String!) {
    getPostsLinkByUsername(username: $username) {
      id
      linkOwner
      destinatario
      estado
      informacion
      createdAt
    }
  }
`;

const FETCH_POST_LINK = gql`
query ($postId: ID!) {
    getPosLink(postId: $postId) {
      id
      estado
      destinatario
      informacion
      emailOwner
      createdAt
    }
  }
`;

const CREATE_LINK_MUTATION = gql`
mutation($titulo: String!, $cuerpo: String!, $username: String!, $email: String!){
  createLinksTests(titulo: $titulo, username: $username, cuerpo: $cuerpo, email: $email) {
    id
    destinatario
    informacion
    linkOwner
    createdAt
    vectorcreatedAt
  }
  
}
`;




export default Chat;
