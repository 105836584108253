import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import './SurveyComponent.css';
import './LoadingEffect.css';
import HelpEncuesta from '../helpEncuestaLink';

const CALCULATE_DISC_MUTATION = gql`
  mutation conductualLINK($d: String!, $i: String!, $s: String!, $c: String!, $username: String!, $email: String!, $name: String!, $dob: String!, $lastname: String!, $emailOwner: String!, $linkID: ID!) {
    conductualLINK(d: $d, i: $i, s: $s, c: $c, username: $username, email: $email, name: $name, dob: $dob, lastname: $lastname, emailOwner: $emailOwner, linkID: $linkID)
  }
`;

const FETCH_POST_LINK = gql`
query ($postId: ID!) {
    getPosLink(postId: $postId) {
      id
      estado
      destinatario
      informacion
      emailOwner
      linkOwner
      createdAt
    }
  }
`;

const SurveyComponent = () => {
    



    const questions = [
        { text: "Iniciador(a)", tipo: "D", cuarteto: 1 },
        { text: "Estimulante", tipo: "I", cuarteto: 1 },
        { text: "Considerado(a)", tipo: "S", cuarteto: 1 },
        { text: "Evaluador(a)", tipo: "C", cuarteto: 1 },
      
        { text: "Agresivo(a)", tipo: "D", cuarteto: 2 },
        { text: "Alegre", tipo: "I", cuarteto: 2 },
        { text: "Amable", tipo: "S", cuarteto: 2 },
        { text: "Cuidadoso(a)", tipo: "C", cuarteto: 2 },
      
        { text: "Evaluador(a)", tipo: "D", cuarteto: 3 },
        { text: "Ameno(a)", tipo: "I", cuarteto: 3 },
        { text: "Bondadoso(a)", tipo: "S", cuarteto: 3 },
        { text: "Preciso(a)", tipo: "C", cuarteto: 3 },
      
        { text: "Vigoroso(a)", tipo: "D", cuarteto: 4 },
        { text: "Cautivador(a)", tipo: "I", cuarteto: 4 },
        { text: "Leal", tipo: "S", cuarteto: 4 },
        { text: "Cuida los Detalles", tipo: "C", cuarteto: 4 },
      
        { text: "Autoritario(a)", tipo: "D", cuarteto: 5 },
        { text: "Amigable", tipo: "I", cuarteto: 5 },
        { text: "Tranquilo(a)", tipo: "S", cuarteto: 5 },
        { text: "Analítico(a)", tipo: "C", cuarteto: 5 },
      
        { text: "Osado(a)", tipo: "D", cuarteto: 6 },
        { text: "Extrovertido(a)", tipo: "I", cuarteto: 6 },
        { text: "Paciente", tipo: "S", cuarteto: 6 },
        { text: "Reflexivo(a)", tipo: "C", cuarteto: 6 },
      
        { text: "Enérgico(a)", tipo: "D", cuarteto: 7 },
        { text: "Comunicativo(a)", tipo: "I", cuarteto: 7 },
        { text: "Gentil", tipo: "S", cuarteto: 7 },
        { text: "Meticuloso(a)", tipo: "C", cuarteto: 7 },
      
        { text: "Audaz", tipo: "D", cuarteto: 8 },
        { text: "Jovial", tipo: "I", cuarteto: 8 },
        { text: "Receptivo(a)", tipo: "S", cuarteto: 8 },
        { text: "Cauteloso(a)", tipo: "C", cuarteto: 8 },
      
        { text: "Competitivo(a)", tipo: "D", cuarteto: 9 },
        { text: "Ingenioso(a)", tipo: "I", cuarteto: 9 },
        { text: "Tenaz", tipo: "S", cuarteto: 9 },
        { text: "Prudente", tipo: "C", cuarteto: 9 },
      
        { text: "Impetuoso(a)", tipo: "D", cuarteto: 10 },
        { text: "Persuasivo(a)", tipo: "I", cuarteto: 10 },
        { text: "Pacifico(a)", tipo: "S", cuarteto: 10 },
        { text: "Sagaz", tipo: "C", cuarteto: 10 },
      
        { text: "Proactivo(a)", tipo: "D", cuarteto: 11 },
        { text: "Promotor(a)", tipo: "I", cuarteto: 11 },
        { text: "Constante", tipo: "S", cuarteto: 11 },
        { text: "Metódico(a)", tipo: "C", cuarteto: 11 },
      
        { text: "Directo(a)", tipo: "D", cuarteto: 12 },
        { text: "Encantador(a)", tipo: "I", cuarteto: 12 },
        { text: "Moderado(a)", tipo: "S", cuarteto: 12 },
        { text: "Reservado(a)", tipo: "C", cuarteto: 12 },
      
        { text: "Habla Directo", tipo: "D", cuarteto: 13 },
        { text: "Contento(a)", tipo: "I", cuarteto: 13 },
        { text: "Controlado(a)", tipo: "S", cuarteto: 13 },
        { text: "Sensible", tipo: "C", cuarteto: 13 },
      
        { text: "Dominante", tipo: "D", cuarteto: 14 },
        { text: "Popular", tipo: "I", cuarteto: 14 },
        { text: "Tolerante", tipo: "S", cuarteto: 14 },
        { text: "Perfeccionista", tipo: "C", cuarteto: 14 },
      
        { text: "Insistente", tipo: "D", cuarteto: 15 },
        { text: "Vivaz", tipo: "I", cuarteto: 15 },
        { text: "Comedido(a)", tipo: "S", cuarteto: 15 },
        { text: "Lógico(a)", tipo: "C", cuarteto: 15 },
      
        { text: "Ideas Firmes", tipo: "D", cuarteto: 16 },
        { text: "Desenvuelto(a)", tipo: "I", cuarteto: 16 },
        { text: "Adaptable", tipo: "S", cuarteto: 16 },
        { text: "Investigador(a)", tipo: "C", cuarteto: 16 },
      
        { text: "Pionero(a)", tipo: "D", cuarteto: 17 },
        { text: "Espontáneo(a)", tipo: "I", cuarteto: 17 },
        { text: "De trato Fácil", tipo: "S", cuarteto: 17 },
        { text: "Discerniente", tipo: "C", cuarteto: 17 },
      
        { text: "Le agrada discutir", tipo: "D", cuarteto: 18 },
        { text: "Animado(a)", tipo: "I", cuarteto: 18 },
        { text: "Colaborador", tipo: "S", cuarteto: 18 },
        { text: "Prevenido(a)", tipo: "C", cuarteto: 18 },
      
        { text: "Ideas Firmes", tipo: "D", cuarteto: 19 },
        { text: "Alegre", tipo: "I", cuarteto: 19 },
        { text: "Ecuánime", tipo: "S", cuarteto: 19 },
        { text: "Apegado(a) a las normas", tipo: "C", cuarteto: 19 },
      
        { text: "Autoritario(a)", tipo: "D", cuarteto: 20 },
        { text: "Estimulante", tipo: "I", cuarteto: 20 },
        { text: "Perseverante", tipo: "S", cuarteto: 20 },
        { text: "Obediente", tipo: "C", cuarteto: 20 },
      
        { text: "Osado(a)", tipo: "D", cuarteto: 21 },
        { text: "Franco(a)", tipo: "I", cuarteto: 21 },
        { text: "Sagaz", tipo: "S", cuarteto: 21 },
        { text: "Cuidadoso(a)", tipo: "C", cuarteto: 21 },
      
        { text: "Enérgico(a)", tipo: "D", cuarteto: 22 },
        { text: "Elocuente", tipo: "I", cuarteto: 22 },
        { text: "Persistente", tipo: "S", cuarteto: 22 },
        { text: "Evaluador(a)", tipo: "C", cuarteto: 22 },
      
        { text: "Competitivo(a)", tipo: "D", cuarteto: 23 },
        { text: "Sociable", tipo: "I", cuarteto: 23 },
        { text: "Generoso(a)", tipo: "S", cuarteto: 23 },
        { text: "Precavido(a)", tipo: "C", cuarteto: 23 },
      
        { text: "Agresivo(a)", tipo: "D", cuarteto: 24 },
        { text: "Popular", tipo: "I", cuarteto: 24 },
        { text: "Calmado(a)", tipo: "S", cuarteto: 24 },
        { text: "Investigador(a)", tipo: "C", cuarteto: 24 }
      ];


    const steps = Math.ceil(questions.length / (4 * 4)) + 4; // Agregar 4 pasos adicionales (nombre, apellido, email, fecha de nacimiento)
    const groupsPerStep = 4;

    const [currentStep, setCurrentStep] = useState(0);
    const [responses, setResponses] = useState(
        Array(steps - 4).fill().map(() => 
            Array(groupsPerStep).fill().map(() => 
                ({ mas: null, menos: null })
            )
        )
    );
    const [scores, setScores] = useState({ D: 0, I: 0, S: 0, C: 0 });
    const [result, setResult] = useState(null);
    const [loadingMessage, setLoadingMessage] = useState(<span className='loading-text'>Cargando...</span>);
    const [userlink, setUserLink] = useState('');
    const [destinatario, setDestinatario] = useState('');
    const [email, setEmail] = useState('');
    const [emailOwner, setEmailOwner] = useState('');
    const [lastname, setLastname] = useState('');
    const [name, setName] = useState('');  //// linkID
    const [linkID, setLinkID] = useState('');  //// linkID
    const [dob, setDob] = useState('');
    const [estado, setEstado] = useState('');
    const [isModalHELP, setIsModalHELP] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const { postId } = useParams();
    const { loading, error, data, refetch } = useQuery(FETCH_POST_LINK, {
        variables: { postId },
        skip: postId ? false : true,
    });

    useEffect(() => {
        if (data && data.getPosLink) {
            const post = data.getPosLink;
            setUserLink(post.linkOwner);
            setDestinatario(post.destinatario);
            setEmailOwner(post.emailOwner);
            setEstado(post.estado);
            setLinkID(postId);
        }
    }, [data]);

    const username = userlink;

    const [conductualLINK] = useMutation(CALCULATE_DISC_MUTATION);

    const handleChange = (groupIndex, questionIndex, type) => {
        const newResponses = [...responses];

        if (type === 'mas' && newResponses[currentStep - 4][groupIndex].menos === questionIndex) {
            newResponses[currentStep - 4][groupIndex].menos = null;
        }
        if (type === 'menos' && newResponses[currentStep - 4][groupIndex].mas === questionIndex) {
            newResponses[currentStep - 4][groupIndex].mas = null;
        }

        newResponses[currentStep - 4][groupIndex][type] = questionIndex;
        setResponses(newResponses);
    };

    useEffect(() => {
        if (currentStep > 3) {
            const calculateScores = () => {
                const newScores = { D: 0, I: 0, S: 0, C: 0 };

                responses.forEach(step => {
                    step.forEach(group => {
                        if (group.mas !== null) {
                            const question = questions[(currentStep - 4) * groupsPerStep * 4 + group.mas];
                            newScores[question.tipo]++;
                        }
                        if (group.menos !== null) {
                            const question = questions[(currentStep - 4) * groupsPerStep * 4 + group.menos];
                            newScores[question.tipo]--;
                        }
                    });
                });

                setScores(newScores);
            };

            calculateScores();
        }
    }, [responses, currentStep]);

    const handleNextStep = () => {
        if (currentStep < steps - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrevStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    
    




    const handleSubmit = async () => {
        setShowLoading(true);
        setLoadingMessage(<span className='loading-text'>Procesando información! Por favor espere.</span>);
    
        const formatDate = (date) => {
            const [year, month, day] = date.split('-');
            return `${day}${month}${year}`;
        };
    
        const formattedDob = formatDate(dob);
    
        try {
            const { data } = await conductualLINK({ 
                variables: { 
                    d: scores.D.toString(), 
                    i: scores.I.toString(), 
                    s: scores.S.toString(), 
                    c: scores.C.toString(),
                    username: username,
                    email: email,
                    name: name,
                    dob: formattedDob,
                    lastname: lastname,
                    emailOwner: emailOwner,
                    linkID: linkID
                } 
            });
    
            setResult(data.conductualLINK);
            setLoadingMessage(<span className='loading-text'>Procesamiento completo. Actualizando estado...</span>);
    
            setTimeout(async () => {
                try {
                    const refetchResult = await refetch();
                    setEstado(refetchResult.data.getPosLink.estado);
    
                    const expectedResponses = [
                        "Subactivo", "Objetivo", "Creativo", "Especialista", "Investigador", "Perfeccionista", "Realizador", "Resolutivo", "Desconcertante", "Orientado a resultado", "Agente", "Alentador", "Consejero", "Persuasivo", "Profesional", "Promotor", "Superactivo"
                    ];
    
                    if (data.conductualLINK === expectedResponses.join(',')) {
                        setLoadingMessage(<span className='loading-text'>Gracias por completar el test.</span>);
                    } else if (refetchResult.data.getPosLink.estado === 'completado') {
                        setLoadingMessage(<span className='loading-text'>La encuesta ya ha sido completada</span>);
                        setTimeout(() => {
                            setLoadingMessage(<span className='loading-text'>Gracias por completar el test</span>);
                            setShowLoading(false);
                        }, 2000);
                    }
                } catch (refetchError) {
                    console.error('Error al refetchear el estado:', refetchError);
                    setLoadingMessage('Error al actualizar el estado.');
                    setShowLoading(false);
                }
            }, 5000);
        } catch (error) {
            console.error('Error al calcular DISC:', error);
            setLoadingMessage('Error durante el procesamiento.');
            setShowLoading(false);
        }
    };
    
    useEffect(() => {
        if (result) {
            const expectedResponses = [
                "Subactivo", "Objetivo", "Creativo", "Especialista", "Investigador", "Perfeccionista", "Realizador", "Resolutivo", "Desconcertante", "Orientado a resultado", "Agente", "Alentador", "Consejero", "Persuasivo", "Profesional", "Promotor", "Superactivo"
            ];
            if (result === expectedResponses.join(',')) {
                setLoadingMessage(<span className='loading-text'>Gracias por completar el test.</span>);
            } else if (estado === 'completado') {
                setLoadingMessage(<span className='loading-text'>La encuesta ya ha sido completada</span>);
            }
        }
    }, [result, estado]);





    const getCurrentQuestions = () => {
        const start = (currentStep - 4) * groupsPerStep * 4;
        const end = start + groupsPerStep * 4;
        return questions.slice(start, end);
    };

    const renderGroup = (groupIndex, questions) => (
        <div key={groupIndex} className="survey-group">
            {questions.map((question, questionIndex) => (
                <div key={questionIndex} className="survey-item">
                    <div className="question-text">{question.text}</div>
                    <div className="survey-inputs">
                        <div className="input-group">
                            <label>MÁS</label>
                            <input
                                type="radio"
                                name={`mas-${currentStep}-${groupIndex}`}
                                checked={responses[currentStep - 4][groupIndex].mas === questionIndex}
                                onChange={() => handleChange(groupIndex, questionIndex, 'mas')}
                            />
                        </div>
                        <div className="input-group">
                            <label>MENOS</label>
                            <input
                                type="radio"
                                name={`menos-${currentStep}-${groupIndex}`}
                                checked={responses[currentStep - 4][groupIndex].menos === questionIndex}
                                onChange={() => handleChange(groupIndex, questionIndex, 'menos')}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    const isNextStepEnabled = () => {
        if (currentStep === 0) {
            return name.trim() !== '';
        } else if (currentStep === 1) {
            return lastname.trim() !== '';
        } else if (currentStep === 2) {
            return email.trim() !== '';
        } else if (currentStep === 3) {
            return dob.trim() !== '';
        } else {
            return responses[currentStep - 4].every(group => group.mas !== null && group.menos !== null);
        }
    };

    return (
        <div>
            {loading || showLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>{loadingMessage}</div>
            ) : (
                estado === 'pendiente' ? (
                    <>
                        <div className="contexto-link">
                            <div className="survey-container">
                                <h3>Test Conductual-GPT</h3>
                                <h5>Hola {destinatario}</h5>
                                <p className="warningLink">Ingresa datos reales para completar el test; de lo contrario, los resultados no serán precisos ni útiles. Debes ser mayor de 18 años.</p>
                                <div className="survey-steps">
                                    {Array.from({ length: steps }).map((_, index) => (
                                        <span key={index} className={`step-dot ${index <= currentStep ? 'completed' : ''}`}></span>
                                    ))}
                                </div>
                                <div className="survey-content">
                                    {currentStep === 0 && (
                                        <div className="input-step">
                                            <label>Nombre </label>
                                            <input
                                                type="text"
                                                placeholder="Para continuar en Conductual-GPT, ingrese su nombre, por favor..."
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </div>
                                    )}
                                    {currentStep === 1 && (
                                        <div className="input-step">
                                            <label>Apellido </label>
                                            <input
                                                type="text"
                                                placeholder="Por favor, ingrese su apellido..."
                                                value={lastname}
                                                onChange={(e) => setLastname(e.target.value)}
                                            />
                                        </div>
                                    )}
                                    {currentStep === 2 && (
                                        <div className="input-step">
                                            <label>Email </label>
                                            <input
                                                type="email"
                                                placeholder="Por favor, ingrese su correo electrónico..."
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                    )}
                                    {currentStep === 3 && (
                                        <div className="input-step">
                                            <label>Fecha de Nacimiento:</label>
                                            <input
                                                type="date"
                                                placeholder="Ahora, ingrese su fecha de nacimiento, por favor..."
                                                value={dob}
                                                onChange={(e) => setDob(e.target.value)}
                                            />
                                        </div>
                                    )}
                                    {currentStep > 3 && (
                                        <>
                                            <button className="question-button" onClick={() => setIsModalHELP(true)}>
                                                ?
                                            </button>
                                            <label>Encuesta conductual DiSC - haz click en <strong>?</strong> para instrucciones</label>
                                            <div className="survey-groups">
                                                {Array.from({ length: groupsPerStep }).map((_, groupIndex) => {
                                                    const groupQuestions = getCurrentQuestions().slice(groupIndex * 4, groupIndex * 4 + 4);
                                                    return renderGroup(groupIndex, groupQuestions);
                                                })}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="survey-navigation">
                                    {currentStep > 0 && (
                                        <button onClick={handlePrevStep} className="navi-button">
                                            Anterior
                                        </button>
                                    )}
                                    {currentStep < steps - 1 ? (
                                        <button
                                            onClick={handleNextStep}
                                            className="navi-button"
                                            disabled={!isNextStepEnabled()}
                                        >
                                            Siguiente
                                        </button>
                                    ) : (
                                        <button
                                            onClick={handleSubmit}
                                            className="navi-button"
                                            disabled={!isNextStepEnabled()}
                                        >
                                            Enviar respuestas
                                        </button>
                                    )}
                                </div>
                                {result && (
                                    <p className="warningLink">{loadingMessage}</p>
                                )}
                            </div>
                        </div>
                        <HelpEncuesta isOpen={isModalHELP} onClose={() => setIsModalHELP(false)} />
                    </>
                ) : (
                    <div className="contexto-link">
                        <div className="survey-container">
                            <p className='loading-text'>El enlace ya ha sido utilizado. Puedes cerrar el navegador.</p>
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default SurveyComponent;
