import React, { useState, useRef, useEffect } from 'react';
import '../App.css'; 
import './encuesta/LoadingEffect.css';
import { 
    FaArrowDown, 
    FaDownload,
    FaRedo, 
    FaCaretDown, 
    FaArrowRight, 
    FaSignInAlt, 
    FaSignOutAlt,
} from 'react-icons/fa'; 
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import Terminos from './terminosDeUso';
import Suscripciones from './suscripciones';
import './Modal.css';
import FilteredPosts from './historialLinks';
import { generatePdf } from './modulos-propios/generatePDF';
import { StreamingText } from './modulos-propios/streamingText';
import useScrollToBottom from './modulos-propios/useScrolledToBottom'; 
import useClickOutside from './modulos-propios/useClickOutside'; 
import Nuevo from './icons/nuevo';
import AbrirSide from './icons/arrowLeft';
import CerrarSide from './icons/arrowRigth';
import client from '../apolloClient';
import { useLocation  } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const Chat = () => {
  const [showSidebar, setShowSidebar] = useState(true); 
  const [showDropdown, setShowDropdown] = useState(false);
  useClickOutside(showDropdown, setShowDropdown); 
  const [currentChat, setCurrentChat] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuscripcionesOpen, setSuscripcionesOpen] = useState(false);
  const [messageInput, setMessageInput] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const [step, setStep] = useState(1);
  const [title, setTitle] = useState('');
  const [info, setInfo] = useState('');
  const [message, setMessage] = useState('');
  const messagesEndRef = useRef(null);
  const bodymessagesEndRef = useRef(null);
  const isScrolledToBottom = useScrollToBottom(bodymessagesEndRef); 
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [showLoading, setShowLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(<span className='loading-text'>Procesando informacion. Creando Link...</span>);
  const { loginWithRedirect } = useAuth0();
  const { logout } = useAuth0();
  const navigate = useNavigate();
  
  const [submitPostrain] = useMutation(CREATE_LINK_MUTATION);

  const useradmin = [
    process.env.REACT_APP_ADMIN,
    process.env.REACT_APP_ADMIN2,
    process.env.REACT_APP_ADMIN3,
    process.env.REACT_APP_ADMIN4
  ];

  const isAdmin = (currentUser) => {
    return useradmin.includes(currentUser);
  };

  const handleTitleSubmit = (event) => {
    event.preventDefault();
    if (title.trim() === '') {
      setMessage('Por favor ingresa un título.');
    } else {
      setMessage('');
      setStep(2);
    }
  };

  const handleInfoSubmit = (event) => {
    event.preventDefault();
    if (info.trim() === '') {
      setMessage('Por favor ingresa la información denominada cuerpo.');
    } else {
      setMessage('');
      handleNewPostrain();
      setShowLoading(true);
    }
  };
  
  const username = user?.sub?.replace(/[^a-zA-Z0-9]/g, '');

  const { loading, error, data, refetch } = useQuery(FETCH_POSTS_QUERY_USER_LINK, {
    variables: { username }
  });


  const location = useLocation();

  useEffect(() => {
    if (location.state?.refetchNeeded) {
      refetch();
    }
  }, [location.state, refetch]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowSidebar(false);
      }
    };
    handleResize(); 
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [botResponses, setBotResponses] = useState([]);
  useEffect(() => {
    const newBotResponses = currentChat.filter(message => message.sender === 'bot' && !botResponses.includes(message.text));
    setBotResponses(prevBotResponses => [
      ...prevBotResponses,
      ...newBotResponses.map(message => message.text)
    ]);
  }, [currentChat, messageInput]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
    }
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleRegenerate = () => {
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const [createdPost, setCreatedPost] = useState(null);

  const createPostMutation = () => {
    return client.mutate({
      mutation: CREATE_LINK_MUTATION,
      variables: { titulo: title , cuerpo: info, username: username , email: user.email},
      onCompleted: (data) => {
        console.log('Mutación completada: ', data);
      },
      onError: (error) => {
        console.error('Error en la mutación: ', error);
      }
    });
  };

  const callCreatePost = async (refetch) => {
    try {
      const response = await createPostMutation();
      console.log("Llamada a createPostMutation exitosa:", response.data.createLinksTests.id);
      const postId = response.data.createLinksTests.id;
      await refetch();
      setShowLoading(false);
      navigate(`/estadoLink/${postId}`);
    } catch (error) {
      console.error("Error al llamar a createPostMutation:", error);
    }
  };

  const handleNewChat = () => {
    navigate(`/new/chat`);
    refetch();
  };

  const handleNewPostrain = () => {
    callCreatePost(refetch);
  };

 

  return (
    <div className="chat-container">
      {!createdPost && showLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>{loadingMessage}</div>
      ) : (
        <div className="spinner-container">
        <div className="contexto-container" >
          <h3>Generar link de Conductual-GPT</h3>
          <p>Por favor, ingrese la información necesaria para generar el enlace...</p>
          <p class="warning">Ingrese datos reales de la persona que completará el test. De lo contrario, los resultados obtenidos podrían no ser precisos ni útiles.</p>
          <p class="info"> En el campo de información, ingrese detalles como el puesto al que aplicará o alguna característica que le permita identificar y encontrar fácilmente el enlace generado más adelante.</p>
        </div>
      </div>
      )}
      <div className="floating-menu">
        <img src='/images/LOGO-conductualGPT-01.svg' className="floating-menu-logo-2" />
        <div className="sidebar-buttons">
          {!isAuthenticated && (
            <button onClick={() => loginWithRedirect()}>
              <FaSignInAlt />
              <span>Ingresar</span>
            </button>
          )}
        </div>
        {isAuthenticated && (
          <div className="profile-container">
            <img src={user.picture} alt={user.name} />
            <div id="dropdown" className="dropdown">
              <button onClick={handleDropdownToggle}>
                <FaCaretDown />
              </button>
              {showDropdown && (
                <div className="dropdown-menu">
                  
                  
                    <button>
                      <a className='button-link' href="#" onClick={() => navigate(`/new/chat`)}>Ir al Chat</a>
                    </button>
                 
                    <button>
                      <a className='button-link' href="#" onClick={() => setSuscripcionesOpen(true, setShowSidebar(false)) }>Suscripcion</a>
                    </button>
                    {isAdmin(user.sub) && ( <button><a className='button-link' href="#" onClick={() => navigate(`/trained`)}>Entrenar</a></button>)}
                  <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                    <span>Salir</span>
                    <FaSignOutAlt />
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {isAuthenticated && (
        <div className={`sidebar ${showSidebar ? 'open' : 'close'}`}>
          <div className="sidebar-toggle" onClick={toggleSidebar}>
            {showSidebar ? <AbrirSide /> : <CerrarSide />}
          </div>
          <div className="sidebar-content">
            <div className="button-container">
              <button onClick={handleNewChat}>
                <Nuevo size={10}/>
                <p>Ir al chat</p>
              </button>
            </div>
            <p>Links creados.</p>
            <FilteredPosts refetch={refetch} user={user.sub} />
          </div>
        </div>
      )}
      <div className="main">
        {isAuthenticated && (
          <div className="input-container">
            {step === 1 && (
              <form onSubmit={handleTitleSubmit}>
                <label>
                  Ingresar nombre del destinatario
                  <input
                    type="text"
                    placeholder="Ingresar nombre del destinatario..."
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </label>
                <button type="submit" ><FaArrowRight size={15} /></button>
                {message && <p style={{ color: 'red' }}>{message}</p>}
              </form>
            )}
            {step === 2 && (
              <form onSubmit={handleInfoSubmit}>
                <label>
                Ingrese la información o descripción del puesto para el cual se está considerando al candidato.
                  <input
                    type="text"
                    placeholder="Ingresar información del puesto..."
                    value={info}
                    onChange={(e) => setInfo(e.target.value)}
                  />
                </label>
                <button type="submit" ><FaArrowRight size={15} /></button>
                {message && <p style={{ color: 'red' }}>{message}</p>}
              </form>
            )}
          </div>
        )}
        <div className="disclaimer-base-container">
          <p>Conductual-GPT ™ 2024 | by Nerualdinamica LLC</p>
        </div>
      </div>
      {isAuthenticated && (
        <>
          {bodymessagesEndRef.current && isScrolledToBottom === false && (
            <div className="scroll-to-bottom" onClick={scrollToBottom}>
              <FaArrowDown />
            </div>
          )}
        </>
      )}
      <div className="disclaimer-base-container-terminos">
        <a className='button-link' href="#" onClick={() => setIsModalOpen(true)}>Terminos de uso.</a>
        <Terminos isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        <Suscripciones isOpen={isSuscripcionesOpen} onClose={() => setSuscripcionesOpen(false)} />
      </div>
    </div>
  );
};




const FETCH_POSTS_QUERY_USER_LINK = gql`
  query getPostsLinkByUsername($username: String!) {
    getPostsLinkByUsername(username: $username) {
      id
      linkOwner
      destinatario
      estado
      informacion
      createdAt
    }
  }
`;


const CREATE_LINK_MUTATION = gql`
mutation($titulo: String!, $cuerpo: String!, $username: String!, $email: String!){
  createLinksTests(titulo: $titulo, username: $username, cuerpo: $cuerpo, email: $email) {
    id
    destinatario
    informacion
    linkOwner
    createdAt
    vectorcreatedAt
  }
  
}
`;




export default Chat;
