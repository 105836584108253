import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
//import InstructionsModal from './InstructionsModal';
import './SurveyComponent.css';
//import './InstructionsModal.css';


const CALCULATE_DISC_MUTATION = gql`
  mutation clasifierDISC($d: String!, $i: String!, $s: String!, $c: String!, $username: String!, $email: String!, $name: String!, $dob: String!) {
    clasifierDISC(d: $d, i: $i, s: $s, c: $c, username: $username, email: $email, name: $name, dob: $dob, )
  }
`;

const SurveyComponent = ({user, email, refetch}) => {
    const username = user.replace(/[^a-zA-Z0-9]/g, '');

    const questions = [
        { text: "Iniciador(a)", tipo: "D", cuarteto: 1 },
        { text: "Estimulante", tipo: "I", cuarteto: 1 },
        { text: "Considerado(a)", tipo: "S", cuarteto: 1 },
        { text: "Evaluador(a)", tipo: "C", cuarteto: 1 },
      
        { text: "Agresivo(a)", tipo: "D", cuarteto: 2 },
        { text: "Alegre", tipo: "I", cuarteto: 2 },
        { text: "Amable", tipo: "S", cuarteto: 2 },
        { text: "Cuidadoso(a)", tipo: "C", cuarteto: 2 },
      
        { text: "Evaluador(a)", tipo: "D", cuarteto: 3 },
        { text: "Ameno(a)", tipo: "I", cuarteto: 3 },
        { text: "Bondadoso(a)", tipo: "S", cuarteto: 3 },
        { text: "Preciso(a)", tipo: "C", cuarteto: 3 },
      
        { text: "Vigoroso(a)", tipo: "D", cuarteto: 4 },
        { text: "Cautivador(a)", tipo: "I", cuarteto: 4 },
        { text: "Leal", tipo: "S", cuarteto: 4 },
        { text: "Cuida los Detalles", tipo: "C", cuarteto: 4 },
      
        { text: "Autoritario(a)", tipo: "D", cuarteto: 5 },
        { text: "Amigable", tipo: "I", cuarteto: 5 },
        { text: "Tranquilo(a)", tipo: "S", cuarteto: 5 },
        { text: "Analítico(a)", tipo: "C", cuarteto: 5 },
      
        { text: "Osado(a)", tipo: "D", cuarteto: 6 },
        { text: "Extrovertido(a)", tipo: "I", cuarteto: 6 },
        { text: "Paciente", tipo: "S", cuarteto: 6 },
        { text: "Reflexivo(a)", tipo: "C", cuarteto: 6 },
      
        { text: "Enérgico(a)", tipo: "D", cuarteto: 7 },
        { text: "Comunicativo(a)", tipo: "I", cuarteto: 7 },
        { text: "Gentil", tipo: "S", cuarteto: 7 },
        { text: "Meticuloso(a)", tipo: "C", cuarteto: 7 },
      
        { text: "Audaz", tipo: "D", cuarteto: 8 },
        { text: "Jovial", tipo: "I", cuarteto: 8 },
        { text: "Receptivo(a)", tipo: "S", cuarteto: 8 },
        { text: "Cauteloso(a)", tipo: "C", cuarteto: 8 },
      
        { text: "Competitivo(a)", tipo: "D", cuarteto: 9 },
        { text: "Ingenioso(a)", tipo: "I", cuarteto: 9 },
        { text: "Tenaz", tipo: "S", cuarteto: 9 },
        { text: "Prudente", tipo: "C", cuarteto: 9 },
      
        { text: "Impetuoso(a)", tipo: "D", cuarteto: 10 },
        { text: "Persuasivo(a)", tipo: "I", cuarteto: 10 },
        { text: "Pacifico(a)", tipo: "S", cuarteto: 10 },
        { text: "Sagaz", tipo: "C", cuarteto: 10 },
      
        { text: "Proactivo(a)", tipo: "D", cuarteto: 11 },
        { text: "Promotor(a)", tipo: "I", cuarteto: 11 },
        { text: "Constante", tipo: "S", cuarteto: 11 },
        { text: "Metódico(a)", tipo: "C", cuarteto: 11 },
      
        { text: "Directo(a)", tipo: "D", cuarteto: 12 },
        { text: "Encantador(a)", tipo: "I", cuarteto: 12 },
        { text: "Moderado(a)", tipo: "S", cuarteto: 12 },
        { text: "Reservado(a)", tipo: "C", cuarteto: 12 },
      
        { text: "Habla Directo", tipo: "D", cuarteto: 13 },
        { text: "Contento(a)", tipo: "I", cuarteto: 13 },
        { text: "Controlado(a)", tipo: "S", cuarteto: 13 },
        { text: "Sensible", tipo: "C", cuarteto: 13 },
      
        { text: "Dominante", tipo: "D", cuarteto: 14 },
        { text: "Popular", tipo: "I", cuarteto: 14 },
        { text: "Tolerante", tipo: "S", cuarteto: 14 },
        { text: "Perfeccionista", tipo: "C", cuarteto: 14 },
      
        { text: "Insistente", tipo: "D", cuarteto: 15 },
        { text: "Vivaz", tipo: "I", cuarteto: 15 },
        { text: "Comedido(a)", tipo: "S", cuarteto: 15 },
        { text: "Lógico(a)", tipo: "C", cuarteto: 15 },
      
        { text: "Ideas Firmes", tipo: "D", cuarteto: 16 },
        { text: "Desenvuelto(a)", tipo: "I", cuarteto: 16 },
        { text: "Adaptable", tipo: "S", cuarteto: 16 },
        { text: "Investigador(a)", tipo: "C", cuarteto: 16 },
      
        { text: "Pionero(a)", tipo: "D", cuarteto: 17 },
        { text: "Espontáneo(a)", tipo: "I", cuarteto: 17 },
        { text: "De trato Fácil", tipo: "S", cuarteto: 17 },
        { text: "Discerniente", tipo: "C", cuarteto: 17 },
      
        { text: "Le agrada discutir", tipo: "D", cuarteto: 18 },
        { text: "Animado(a)", tipo: "I", cuarteto: 18 },
        { text: "Colaborador", tipo: "S", cuarteto: 18 },
        { text: "Prevenido(a)", tipo: "C", cuarteto: 18 },
      
        { text: "Ideas Firmes", tipo: "D", cuarteto: 19 },
        { text: "Alegre", tipo: "I", cuarteto: 19 },
        { text: "Ecuánime", tipo: "S", cuarteto: 19 },
        { text: "Apegado(a) a las normas", tipo: "C", cuarteto: 19 },
      
        { text: "Autoritario(a)", tipo: "D", cuarteto: 20 },
        { text: "Estimulante", tipo: "I", cuarteto: 20 },
        { text: "Perseverante", tipo: "S", cuarteto: 20 },
        { text: "Obediente", tipo: "C", cuarteto: 20 },
      
        { text: "Osado(a)", tipo: "D", cuarteto: 21 },
        { text: "Franco(a)", tipo: "I", cuarteto: 21 },
        { text: "Sagaz", tipo: "S", cuarteto: 21 },
        { text: "Cuidadoso(a)", tipo: "C", cuarteto: 21 },
      
        { text: "Enérgico(a)", tipo: "D", cuarteto: 22 },
        { text: "Elocuente", tipo: "I", cuarteto: 22 },
        { text: "Persistente", tipo: "S", cuarteto: 22 },
        { text: "Evaluador(a)", tipo: "C", cuarteto: 22 },
      
        { text: "Competitivo(a)", tipo: "D", cuarteto: 23 },
        { text: "Sociable", tipo: "I", cuarteto: 23 },
        { text: "Generoso(a)", tipo: "S", cuarteto: 23 },
        { text: "Precavido(a)", tipo: "C", cuarteto: 23 },
      
        { text: "Agresivo(a)", tipo: "D", cuarteto: 24 },
        { text: "Popular", tipo: "I", cuarteto: 24 },
        { text: "Calmado(a)", tipo: "S", cuarteto: 24 },
        { text: "Investigador(a)", tipo: "C", cuarteto: 24 }
      ];
      
      
      const steps = Math.ceil(questions.length / (4 * 4)) + 2; // Agregar 2 pasos adicionales
    const groupsPerStep = 4;

    const [currentStep, setCurrentStep] = useState(0);
    const [responses, setResponses] = useState(
        Array(steps - 2).fill().map(() => 
            Array(groupsPerStep).fill().map(() => 
                ({ mas: null, menos: null })
            )
        )
    );
    const [scores, setScores] = useState({ D: 0, I: 0, S: 0, C: 0 });
    const [result, setResult] = useState(null);
    const [name, setName] = useState('');
    const [dob, setDob] = useState('');

    const [calculateDisc] = useMutation(CALCULATE_DISC_MUTATION);

    const handleChange = (groupIndex, questionIndex, type) => {
        const newResponses = [...responses];

        if (type === 'mas' && newResponses[currentStep - 2][groupIndex].menos === questionIndex) {
            newResponses[currentStep - 2][groupIndex].menos = null;
        }
        if (type === 'menos' && newResponses[currentStep - 2][groupIndex].mas === questionIndex) {
            newResponses[currentStep - 2][groupIndex].mas = null;
        }

        newResponses[currentStep - 2][groupIndex][type] = questionIndex;
        setResponses(newResponses);
    };

    useEffect(() => {
        if (currentStep > 1) {
            const calculateScores = () => {
                const newScores = { D: 0, I: 0, S: 0, C: 0 };

                responses.forEach(step => {
                    step.forEach(group => {
                        if (group.mas !== null) {
                            const question = questions[(currentStep - 2) * groupsPerStep * 4 + group.mas];
                            newScores[question.tipo]++;
                        }
                        if (group.menos !== null) {
                            const question = questions[(currentStep - 2) * groupsPerStep * 4 + group.menos];
                            newScores[question.tipo]--;
                        }
                    });
                });

                setScores(newScores);
            };

            calculateScores();
        }
    }, [responses, currentStep]);

    const handleNextStep = () => {
        if (currentStep < steps - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrevStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleSubmit = async () => {
        console.log('Respuestas enviadas:', responses);
        console.log('Puntajes finales:', scores);

        const formatDate = (date) => {
            const [year, month, day] = date.split('-');
            return `${day}${month}${year}`;
        };

        const formattedDob = formatDate(dob);


        try {
            const { data } = await calculateDisc({ 
                variables: { 
                    d: scores.D.toString(), 
                    i: scores.I.toString(), 
                    s: scores.S.toString(), 
                    c: scores.C.toString(),
                    email,
                    username,
                    name,
                    dob: formattedDob
                } 
            });
            setResult(data.clasifierDISC);
            refetch();
        } catch (error) {
            console.error('Error al calcular DISC:', error);
        }
    };

    const getCurrentQuestions = () => {
        const start = (currentStep - 2) * groupsPerStep * 4;
        const end = start + groupsPerStep * 4;
        return questions.slice(start, end);
    };

    const renderGroup = (groupIndex, questions) => (
        <div key={groupIndex} className="survey-group">
            {questions.map((question, questionIndex) => (
                <div key={questionIndex} className="survey-item">
                    <div className="question-text">{question.text}</div>
                    <div className="survey-inputs">
                        <div className="input-group">
                            <label>MÁS</label>
                            <input
                                type="radio"
                                name={`mas-${currentStep}-${groupIndex}`}
                                checked={responses[currentStep - 2][groupIndex].mas === questionIndex}
                                onChange={() => handleChange(groupIndex, questionIndex, 'mas')}
                            />
                        </div>
                        <div className="input-group">
                            <label>MENOS</label>
                            <input
                                type="radio"
                                name={`menos-${currentStep}-${groupIndex}`}
                                checked={responses[currentStep - 2][groupIndex].menos === questionIndex}
                                onChange={() => handleChange(groupIndex, questionIndex, 'menos')}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    const isNextStepEnabled = () => {
        if (currentStep === 0) {
            return name.trim() !== '';
        } else if (currentStep === 1) {
            return dob.trim() !== '';
        } else {
            return responses[currentStep - 2].every(group => group.mas !== null && group.menos !== null);
        }
    };

    return (
        <div className="survey-container">
           
            <div className="survey-steps">
                {Array.from({ length: steps }).map((_, index) => (
                    <span key={index} className={`step-dot ${index <= currentStep ? 'completed' : ''}`}></span>
                ))}
            </div>
            <div className="survey-content">
           
                {currentStep === 0 && (
                    <div className="input-step">
                        <label>Nombre:</label>
                        <input
                            type="text"
                            placeholder="Para seguir en conductual-gpt por favor ingrese su nombre..."
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                )}
                {currentStep === 1 && (
                    <div className="input-step">
                        <label>Fecha de Nacimiento:</label>
                        <input
                            type="date"
                            placeholder="Para seguir en conductual-gpt por favor ingrese su fecha de nacimiento..."
                            value={dob}
                            onChange={(e) => setDob(e.target.value)}
                        />
                    </div>
                )}
                {currentStep > 1 && (
                    <>
                    <label>Encuenta conductual DiSC - haz click en <strong>?</strong> para instrucciones</label>
                    <div className="survey-groups">
                        
                        {Array.from({ length: groupsPerStep }).map((_, groupIndex) => {
                            const groupQuestions = getCurrentQuestions().slice(groupIndex * 4, groupIndex * 4 + 4);
                            return renderGroup(groupIndex, groupQuestions);
                        })}
                    </div>
                    </>
                )}
            </div>
            <div className="survey-navigation">
                {currentStep > 0 && (
                    <button onClick={handlePrevStep} className="navi-button">
                        Anterior
                    </button>
                )}
                {currentStep < steps - 1 ? (
                    <button
                        onClick={handleNextStep}
                        className="navi-button"
                        disabled={!isNextStepEnabled()}
                    >
                        Siguiente
                    </button>
                ) : (
                    <button
                        onClick={handleSubmit}
                        className="navi-button"
                        disabled={!isNextStepEnabled()}
                    >
                        Enviar respuestas
                    </button>
                )}
            </div>
           
        </div>
    );
};

export default SurveyComponent;